export const defaultPageVariants = {
    initial: {
        opacity: 0,
        y: 500,
    },
    in: {
        opacity: 1,
        y: 0,
    },
    out: {
        opacity: 1,
        y: 50,
    },
};

export const homePageVariants = {
    initial: {
        y: 500,
    },
    in: {
        y: 0,
    },
    out: {
        y: 50,
    },
};