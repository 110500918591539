import React from "react";
import Layout from "../Constants/Layout";

function random(low, high) {
    return Math.random() * (high - low) + low;
}

class Canvas extends React.Component {

    constructor(props) {
        super(props);
        this.canvas = React.createRef();
        this.canvasWidth = 0;
        this.canvasHeight = 0;
        this.particleLength = Layout.isDesktop ? 150 : 70;
        this.particles = [];
        this.particleMaxRadius = Layout.isDesktop ? 35 : 20;
        this.handleResizeBind = this.handleResize.bind(this);
        this.animationID = null;
    }

    componentDidMount() {
        const ctx = this.canvas.current.getContext("2d");
        ctx.fillRect(0,0, 100, 100);

        this.resizeCanvas();

        for (let i = 0; i < this.particleLength; i++) {
            this.particles.push(this.createParticle(i));
        }

        this.animationID = window.requestAnimationFrame(() => this.updateCanvas());
        window.addEventListener('resize', this.handleResizeBind, false);
    }

    handleResize() {
        this.resizeCanvas();
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.handleResizeBind, false);
        cancelAnimationFrame(this.animationID);
    }

    resizeCanvas() {
        this.canvasWidth = document.body.offsetWidth;
        this.canvasHeight = this.canvas.current.nextElementSibling.offsetHeight;
        this.canvas.current.width = this.canvasWidth;
        this.canvas.current.height = this.canvasHeight;
        this.context = this.canvas.current.getContext('2d');
    }

    createParticle(id, isRecreate) {
        const radius = random(10, this.particleMaxRadius);
        const x = isRecreate ? -radius - random(0, this.canvasWidth) : random(0, this.canvasWidth);
        let y = random(this.canvasHeight / 2 - 50, this.canvasHeight / 2 + 50);
        y += random(-500, 200);
        const alpha = random(0.05, 1);

        return {
            id: id,
            x: x,
            y: y,
            startY: y,
            radius: radius,
            defaultRadius: radius,
            startAngle: 0,
            endAngle: Math.PI * 2,
            alpha: alpha,
            color: {r: random(50, 100), g: random(0, 50), b: 200},
            speed: alpha / 1.5,
            amplitude: random(50, 200),
            isBurst: false
        };
    }

    drawParticles() {
        this.particles.forEach(particle => {
            this.moveParticle(particle);
            this.context.beginPath();
            this.context.fillStyle = `rgba(${particle.color.r}, ${particle.color.g}, ${particle.color.b}, ${particle.alpha})`;
            this.context.arc(particle.x, particle.y, particle.radius, particle.startAngle, particle.endAngle);
            this.context.fill();
        });
    }

    moveParticle(particle) {
        particle.x += particle.speed;
        particle.y = particle.startY + particle.amplitude * Math.sin(((particle.x / 6) * Math.PI) / 180);
    }

    updateCanvas() {
        this.context.clearRect(0, 0, this.canvasWidth + this.particleMaxRadius * 2, this.canvasHeight);

        this.drawParticles();

        this.particles.forEach(particle => {
            if (particle.x - particle.radius >= this.canvasWidth) {
                this.particles[particle.id] = this.createParticle(particle.id, true);
            }
        });

        this.animationID = requestAnimationFrame(() => this.updateCanvas());
    }

    render() {
        return(
            <canvas ref={this.canvas} width={this.canvasWidth} height={this.canvasHeight} />
        )
    }
}

export default Canvas;