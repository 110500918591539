import React from "react";
import {motion} from "framer-motion";
import {defaultPageVariants} from "../Components/AnimationVariants";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faMobileAlt } from '@fortawesome/free-solid-svg-icons';
import PageFeature from "../Components/PageFeature";
import Carousel from "../Components/Carousel";
import MetaTags from 'react-meta-tags';

const images = [
    require('../images/stw/feature-2.jpg'),
    require('../images/stw/feature-3.jpg'),
    require('../images/stw/feature-4.jpg')
];

const images2 = [
    require('../images/stw/feature-5.jpg'),
    require('../images/stw/feature-6.jpg'),
    require('../images/stw/feature-7.jpg'),
    require('../images/stw/feature-8.jpg')
];

function StwPlanner() {
    return (
        <motion.div
            initial="initial"
            animate="in"
            exit="out"
            variants={defaultPageVariants}
            className="single-page">

            <MetaTags>
                <title>STW Planner - Twigsby Studios</title>
                <meta name="description" content="A Fortnite Save the World Hero Site, where users can view and filter the available Missions, browse and search User Profiles, and Leaderboards." />
                <meta property="og:title" content="STW Planner - Twigsby Studios" />
                <meta property="og:image" content="/logo512.png" />
            </MetaTags>

            <div className="stw-feature">

                <div className="section p-0">
                    <div className="page-feature">
                        <img alt="STW Planner Logo" src={require("../images/stw/logo.svg")}/>
                    </div>
                </div>

                <div className="section">
                    <div className="split-content">
                        <div>
                            <h2>STW Planner</h2>
                            <ul className="highlight-list">
                                <li>Fortnite - Save the World Game Companion</li>
                                <li>Profile Viewer</li>
                                <li>Loadout Creator</li>
                                <li>Item Viewer</li>
                                <li>Mission Checker</li>
                                <li>Storefront Checker</li>
                            </ul>
                        </div>
                        <div>
                            <p><strong>Reasonably detailed summary outlining the project and its aims. Lorem ipsum dolor sit
                                amet, consectetur adipiscing elit. Sed dolor libero, bibendum ac dictum commodo, posuere in
                                tellus. Etiam scelerisque lectus at dui dictum, quis ultricies est dictum. Donec vel condimentum
                                sapien. Maecenas faucibus sodales placerat. Mauris aliquet at erat eget suscipit. Phasellus
                                tincidunt purus ut orci rutrum, vitae efficitur justo laoreet. Donec porttitor mi non odio
                                ultricies euismod.</strong></p>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed dolor libero, bibendum ac dictum
                                commodo, posuere in tellus. Etiam scelerisque lectus at dui dictum, quis ultricies est dictum.
                                Donec vel condimentum sapien. Maecenas faucibus sodales placerat. Mauris aliquet at erat eget
                                suscipit. Phasellus tincidunt purus ut orci rutrum, vitae efficitur justo laoreet. Donec
                                porttitor mi non odio ultricies euismod.</p>
                        </div>
                    </div>
                </div>

                <div className="section p-0">
                    <PageFeature>
                        <img alt="STW Planner Site Overview" src={require("../images/stw/feature-1.jpg")} />
                    </PageFeature>
                </div>

                <div className="section">
                    <div className="split-content">
                        <div>
                            <h2>Technologies Used</h2>
                            <ul className="highlight-list">
                                <li>C# .NET Core 3</li>
                                <li>MS SQL</li>
                                <li>Azure Webjobs</li>
                                <li>HTML5</li>
                                <li>SASS</li>
                                <li>ES6 Javascript</li>
                                <li>React Native</li>
                                <li>Photoshop</li>
                                <li>Illustrator</li>
                            </ul>
                        </div>
                        <div>
                            <p><strong>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed dolor libero, bibendum ac
                                dictum commodo, posuere in tellus. Etiam scelerisque lectus at dui dictum, quis ultricies est
                                dictum. Donec vel condimentum sapien. Maecenas faucibus sodales placerat. Mauris aliquet at erat
                                eget suscipit. Phasellus tincidunt purus ut orci rutrum, vitae efficitur justo laoreet. Donec
                                porttitor mi non odio ultricies euismod.</strong></p>

                            <p>Morbi imperdiet nisl risus, eget venenatis justo rhoncus vitae. Morbi eu tortor vitae turpis
                                euismod finibus at ac nulla. Maecenas tincidunt neque faucibus lobortis consequat. Donec justo
                                lectus, bibendum auctor pretium ac, volutpat et ligula. Cras bibendum enim non tempus
                                condimentum. Morbi tincidunt, lectus sed varius venenatis, quam erat vulputate sapien, ac
                                facilisis quam velit eget mauris. Nullam in tempor sem, in tristique ligula. Fusce quis arcu nec
                                velit sagittis tristique. Nulla lacinia a orci non commodo. Vivamus blandit tincidunt mi sed
                                fermentum. Sed laoreet mattis volutpat. Class aptent taciti sociosqu ad litora torquent per
                                conubia nostra, per inceptos himenaeos. Nam imperdiet neque ac dictum luctus. Integer bibendum
                                auctor turpis, vel malesuada neque tristique ut. Nullam eu elementum eros, ut dictum risus.
                                Pellentesque eget eleifend elit, et pulvinar nibh.</p>

                        </div>
                    </div>
                </div>

                <div className="section p-0">
                    <div className="small-feature">
                        <img alt="STW Planner Mobile 1" src={require("../images/stw/feature-sm-1.png")}/>
                        <img alt="STW Planner Mobile 2" src={require("../images/stw/feature-sm-3.png")}/>
                        <img alt="STW Planner Mobile 3" src={require("../images/stw/feature-sm-2.png")}/>
                        <img alt="STW Planner Mobile 4" src={require("../images/stw/feature-sm-4.png")}/>
                        <div className="hover-helper">
                            <FontAwesomeIcon icon={faMobileAlt} size="2x" />
                        </div>
                    </div>
                </div>

                <Carousel items={images} />

                <div className="section">
                    <div className="split-content">
                        <div>
                            <h2>Anything Else?</h2>
                        </div>
                        <div>
                            <p><strong>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed dolor libero, bibendum ac
                                dictum commodo, posuere in tellus. Etiam scelerisque lectus at dui dictum, quis ultricies est
                                dictum. Donec vel condimentum sapien. Maecenas faucibus sodales placerat. Mauris aliquet at erat
                                eget suscipit. Phasellus tincidunt purus ut orci rutrum, vitae efficitur justo laoreet. Donec
                                porttitor mi non odio ultricies euismod.</strong></p>

                            <p>Morbi imperdiet nisl risus, eget venenatis justo rhoncus vitae. Morbi eu tortor vitae turpis
                                euismod finibus at ac nulla. Maecenas tincidunt neque faucibus lobortis consequat. Donec justo
                                lectus, bibendum auctor pretium ac, volutpat et ligula. Cras bibendum enim non tempus
                                condimentum. Morbi tincidunt, lectus sed varius venenatis, quam erat vulputate sapien, ac
                                facilisis quam velit eget mauris. Nullam in tempor sem, in tristique ligula. Fusce quis arcu nec
                                velit sagittis tristique. Nulla lacinia a orci non commodo. Vivamus blandit tincidunt mi sed
                                fermentum. Sed laoreet mattis volutpat. Class aptent taciti sociosqu ad litora torquent per
                                conubia nostra, per inceptos himenaeos. Nam imperdiet neque ac dictum luctus. Integer bibendum
                                auctor turpis, vel malesuada neque tristique ut. Nullam eu elementum eros, ut dictum risus.
                                Pellentesque eget eleifend elit, et pulvinar nibh.</p>
                        </div>
                    </div>
                </div>
                
                <Carousel items={images2} />

            </div>
        </motion.div>
    );
}

export default StwPlanner;