import React from "react";

export function Logos() {
    return(
        <div className="section">
            <h2>Logo/Identity<span className="highlight">.</span></h2>
            <div className="sub-content landscape graphics">
                <div className="item item--logo item-booboo">
                    <img alt="Boo Boo Logo" src={require("../images/booboo/logo.svg")} />
                </div>
                <div className="item item--logo item-method">
                    <img alt="Method Logo" src={require("../images/method/logo.svg")} />
                </div>
                <div className="item item--logo item-kewlake">
                    <img alt="Kewlake Logo" src={require("../images/kewlake/logo.svg")} />
                </div>
                <div className="item item--video item-piggybank">
                    <img alt="PiggyBank Logo" src={require("../images/piggybank/logo.svg")} />
                </div>
            </div>

            <div className="sub-content fill">
                <div className="item item--video item-theatre">
                    <video controls poster={require("../images/theatre/logo.png")}>
                        <source src={require("../images/theatre/logo-animated.mp4")} type="video/mp4" />
                    </video>
                </div>
            </div>

            <div className="sub-content landscape graphics">

                <div className="item item-pineapple">
                    <img alt="Pineapple Logo" src={require("../images/pineapple/logo.svg")}/>
                </div>

                <div className="item item-djs">
                    <img alt="DJS Logo" src={require("../images/djs/logo.svg")}/>
                </div>

                <div className="item item--logo item-liquidity">
                    <img alt="Liquidity Logo" src={require("../images/liquidity/logo.svg")}/>
                </div>

                <div className="item item--logo item-marbella">
                    <img alt="Marbella Days Logo" src={require("../images/marbella/logo.png")}/>
                </div>

                <div className="item item--logo item-befriend">
                    <img alt="beFriend Logo" src={require("../images/befriend/logo.svg")}/>
                </div>
                <div className="item item--logo item-jess">
                    <img alt="Jessicas Wardrobe Logo" src={require("../images/jessica/logo.svg")}/>
                </div>
            </div>
        </div>
    )
}