import React from 'react';
import './App.css';
import {
    BrowserRouter as Router,
    Route,
} from "react-router-dom";

import StwPlanner from "./Pages/StwPlanner";
import Home from "./Pages/Home";
import ScrollToTop from "./Components/ScrollToTop";
import {AnimatePresence} from "framer-motion";

function App() {
    return (
        <AnimatePresence>
            <Router>
                <ScrollToTop />
                <Route exact path="/" component={Home} />
                <Route path="/stw-planner" component={StwPlanner} />
            </Router>
        </AnimatePresence>
    );
}

export default App;
