import React, { useRef, useState, useLayoutEffect } from "react";
import {
    useViewportScroll,
    useTransform,
    motion
} from "framer-motion";

export default function ParallaxItem({ children, range, opacity }) {
    const { scrollY } = useViewportScroll();
    const ref = useRef();
    const [elementTop, setElementTop] = useState(0);

    const opacityRange = opacity ? [0,1] : [1,1];

    const fade = useTransform(scrollY, [elementTop + 700, elementTop], opacityRange, {
        clamp: false
    });

    const y = useTransform(scrollY, [elementTop, elementTop + 1], range, {
        clamp: false
    });

    useLayoutEffect(() => {
        if (!ref.current) return;

        const onResize = () => {
            setElementTop(ref.current.offsetTop);
        };

        onResize();
        window.addEventListener("resize", onResize);
        return () => window.removeEventListener("resize", onResize);
    }, [ref]);

    return (
        <motion.div ref={ref} style={{ y, opacity: fade, position: 'relative' }}>
            {children}
        </motion.div>
    );
}