import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExpandAlt } from '@fortawesome/free-solid-svg-icons';

class PageFeature extends React.Component {

    constructor(props) {
        super(props);
        this.state = { isActive: false };
        this.handleClick = this.handleClick.bind(this);
    }

    handleClick() {
        this.setState({ isActive: !this.state.isActive });
    }

    render() {
        return(            
            <div className={this.state.isActive ? "feature-hover active" : "feature-hover"} onClick={this.handleClick}>
                {this.props.children}
                <div className="hover-helper">
                    <FontAwesomeIcon icon={faExpandAlt} size="2x" />
                </div>
            </div>
        )
    }
}

export default PageFeature;