import React from "react";
import {AnimatePresence, motion} from "framer-motion";
import { wrap } from "@popmotion/popcorn";
import Layout from "../Constants/Layout";

class Carousel extends React.Component {
    
    constructor(props) {
        super(props);
        this.items = this.props.items;
        this.state = { page: 0, direction: 0 };
        this.swipeConfidenceThreshold = 10000;
        this.variants = {
            enter: (direction) => {
                return {
                    x: direction > 0 ? 1000 : -1000,
                    opacity: 0
                };
            },
            center: {
                zIndex: 1,
                x: 0,
                opacity: 1
            },
            exit: () => {
                return {
                    zIndex: 0,
                    x: 0,
                    opacity: 1
                };
            }
        };
    }

    swipePower(offset, velocity) {
        return Math.abs(offset) * velocity;
    };

    paginate(newDirection) {
        this.setState({ page: this.state.page + newDirection });
        this.setState({ direction: newDirection });
    }

    render() {
        const imageIndex = wrap(0, this.items.length, this.state.page);
        return(
            Layout.isDesktop ? 
                <div className="section p-0">
                    <div className="swipeCarousel">
                        <AnimatePresence initial={false} custom={this.state.direction}>
                            <motion.img
                                key={this.state.page}
                                src={this.items[imageIndex]}
                                custom={this.state.direction}
                                variants={this.variants}
                                initial="enter"
                                animate="center"
                                exit="exit"
                                transition={{
                                    x: { type: "spring", stiffness: 300, damping: 400 },
                                    opacity: { duration: 0.5 }
                                }}
                                drag="x"
                                dragConstraints={{ left: 0, right: 0 }}
                                dragElastic={1}
                                onDragEnd={(e, { offset, velocity }) => {
                                    const swipe = this.swipePower(offset.x, velocity.x);

                                    if (swipe < -this.swipeConfidenceThreshold) {
                                        this.paginate(1);
                                    } else if (swipe > this.swipeConfidenceThreshold) {
                                        this.paginate(-1);
                                    }
                                }}
                            />
                        </AnimatePresence>
                    </div>

                    <motion.div
                        initial={{scale:-1}}
                        whileHover={{ scale: -1.1 }}
                        whileTap={{ scale: -0.95 }}
                        className="prev"
                        onClick={() => this.paginate(-1)}>
                        {"‣"}
                    </motion.div>
                    <motion.div
                        whileHover={{ scale: 1.1 }}
                        whileTap={{ scale: 0.95 }}
                        className="next"
                        onClick={() => this.paginate(1)}>
                        {"‣"}    
                    </motion.div>
                </div> 
            :
            <div class="section p-0">
                {this.items.map((item, key) =>
                    <img alt="Carousel" src={item} key={item.id} />
                )}
            </div>
            
        )
    }
}

export default Carousel;