import React from "react";
import { motion } from "framer-motion";
import { Link } from 'react-router-dom';

const variants = {
    in: {
        transition: { staggerChildren: 0.3 }
    }
};

const itemVariants = {
    open: {
        y: 0,
        opacity: 1,
        transition: {
            y: { stiffness: 1000, velocity: -100 }
        }
    },
    closed: {
        y: 50,
        opacity: 0,
        transition: {
            y: { stiffness: 1000 }
        }
    }
};

const images = {
    'stw-planner': require('../images/stw/logo.svg'),
    'piggy-bank': require('../images/piggybank/logo.svg'),
    'luracia': require('../images/luracia/logo.svg'),
    'djs': require('../images/djs/logo.svg'),
    'leadtree-global': require('../images/leadtree/logo.svg'),
    'pineapple-recruitment': require('../images/pineapple/logo.svg'),
};

const data = [
    {
        'Name': 'STW Planner',
        'Class': 'item item-stw',
        'Page': 'stw-planner',
    },
    {
        'Name': 'PiggyBank',
        'Class': 'item item-piggybank',
        'Page': 'piggy-bank',
    },
    {
        'Name': 'Planet Luracia',
        'Class': 'item item-luracia',
        'Page': 'luracia',
    },
    {
        'Name': 'DJS UK Limited',
        'Class': 'item item-djs',
        'Page': 'djs',
    },
    {
        'Name': 'Leadtree Global',
        'Class': 'item item-leadtree',
        'Page': 'leadtree-global',
    },
    {
        'Name': 'Pineapple Recruitment',
        'Class': 'item item-pineapple',
        'Page': 'pineapple-recruitment',
    }
];

export function Featured() {
    return (
        <div className="section">
            <h2>Featured<span className="highlight">.</span></h2>
            <motion.div
                variants={variants}
                animate="in"
                className="sub-content main">

                {data.map((item,i) => (
                    <motion.div
                        key={i}
                        initial="closed"
                        animate="open"
                        variants={itemVariants}
                        whileHover={{ scale: 1.07 }}
                        whileTap={{ scale: 0.95 }}
                        className={item.Class}>

                        <Link to={item.Page}>
                            <img src={images[item.Page]} alt="" />
                        </Link>

                    </motion.div>
                ))}

            </motion.div>
        </div>
    )
}