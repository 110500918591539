import React from "react";
import Canvas from "./Canvas";
import ParallaxItem from "./Parallax";

export function Header() {
    return (
        <ParallaxItem range={[0, 0.6]}>
            <Canvas />
            <div className="canvas-overlay"></div>
            <ParallaxItem range={[0, -0.3]} opacity={true}>
                <header>
                    <div className="section">
                        <h1>Twigsby Studios</h1>
                        <h3 className="lead">The online portfolio of Christopher Smith, a Full Stack Developer &amp; Visual Designer
                            currently based in Bournemouth, England.</h3>
                    </div>
                </header>
            </ParallaxItem>
        </ParallaxItem>
    )
}