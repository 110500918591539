import {Header} from "../Components/Header";
import {Featured} from "../Components/Featured";
import {Logos} from "../Components/Logos";
import {Graphics} from "../Components/Graphics";
import {Footer} from "../Components/Footer";
import React from "react";
import MetaTags from 'react-meta-tags';

function Home() {
    return (
        <div className="home-wrapper">

            <MetaTags>
                <title>Twigsby Studios</title>
                <meta name="description" content="The online portfolio of Christopher Smith, a Full Stack Developer & Visual Designer currently based in Bournemouth, England." />
                <meta property="og:title" content="Twigsby Studios" />
                <meta property="og:image" content="/logo512.png" />
            </MetaTags>

            <Header />

            <Featured />

            <Logos />

            <Graphics />

            <Footer />
        </div>
    )
}

export default Home;