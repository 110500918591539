import React from "react";

export function Graphics() {
    return (

        <div className="section">
            <h2 id="graphics">Graphics<span className="highlight">.</span></h2>

            <div className="sub-content fill">
                <div className="item item--graphic">
                    <img alt="Piggy Wall" src={require("../images/piggy-wall/feature-xl-2.jpg")}/>
                </div>
            </div>


            <div className="sub-content landscape split">
                <div className="item item--graphic">
                    <img alt="Piggy Car" src={require("../images/piggy-wall/piggy-car.png")}/>
                </div>
                <div className="item item--graphic">
                    <img alt="Piggy Wings" src={require("../images/piggy-wall/piggy-wings.png")}/>
                </div>
            </div>


            <div className="sub-content fill">
                <div className="item item--graphic">
                    <video playsInline autoPlay muted nocontrols="true" loop>
                        <source src={require("../images/leadtree/feature-1.mp4")} type="video/mp4" />
                    </video>
                </div>
            </div>

            <div className="sub-content portrait">
                <div className="item item--graphic">
                    <img alt="Piggy Books" src={require("../images/piggy-wall/piggy-books.png")}/>
                </div>
                <div className="item item--graphic">
                    <img alt="Piggy Food" src={require("../images/piggy-wall/piggy-food.png")}/>
                </div>
            </div>

            <div className="sub-content fill">
                <div className="item item--graphic">
                    <video playsInline autoPlay muted nocontrols="true" loop>
                        <source src={require("../images/luracia/feature.mp4")} type="video/mp4" />
                    </video>
                </div>
            </div>

            <div className="sub-content fill">
                <div className="item item--graphic">
                    <img alt="Piggy Wall" src={require("../images/piggy-wall/feature-xl.jpg")}/>
                </div>
            </div>

            <div className="sub-content portrait">
                <div className="item item--graphic">
                    <img alt="Piggy Face" src={require("../images/piggy-wall/piggy-face.png")}/>
                </div>
                <div className="item item--graphic">
                    <img alt="Piggy Ball" src={require("../images/piggy-wall/piggy-ball.png")}/>
                </div>
            </div>

        </div>

    )
}